export const translationKeys = {
  step1: 'Información básica',
  step2: 'Documentación',
  step3: 'Costos y zonas',
}

export const steps = t => [
  t(translationKeys.step1),
  t(translationKeys.step2),
  t(translationKeys.step3),
]
