export const tenants = [
  {
    value: "adff7f6a-e97d-11eb-9a03-0242ac130003",
    label: "IKÉ México",
    code: "+52",
    id: 1,
  },
  {
    value: "ddaa614e-6514-11ea-4421-0242ac139997",
    label: "IKÉ Argentina",
    code: "+54",
    id: 2,
  },
  {
    value: "d47bd0c6-1512-11ef-9633-0242ac130002",
    label: "IKÉ Colombia",
    code: "+57",
    id: 3,
  },
];

export const translationKeys = {
  english: "Inglés",
  spanish: "Español",
};
