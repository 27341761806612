import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  circularProgress: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  buttons: {
    minWidth: '116px',
    maxWidth: '280px',
    height: '36px',
  },
}))
