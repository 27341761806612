import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	helperText: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	p: {
		marginBlock: 0,
	},
	inputRoot: {
		fontSize: '15px',
		height: '-webkit-fill-available',
	},
}));
