import { api } from "../../../util/Api";
import { getTenantBUs } from "../../services";
const API_URL_BFF = process.env.REACT_APP_BFF_URL;

export const getEconomicActivity = async () => {
  const url = `${API_URL_BFF}/economic-sectors`;
  try {
    const resp = await api.get(url, {});
    return resp.data;
  } catch (e) {
    return { error: e, res: null };
  }
};

export const getCountries = async () => {
  const tenantId = localStorage.getItem("tenant");
  const url = `${API_URL_BFF}/countries/tenant/${tenantId}`;
  try {
    const resp = await api.get(url, {});
    return resp.data;
  } catch (e) {
    return { error: e, res: null };
  }
};

export const getCities = async (id) => {
  const url = `${API_URL_BFF}/cities/country/${id}`;
  try {
    const resp = await api.get(url, {});
    return resp.data;
  } catch (e) {
    return { error: e, res: null };
  }
};

export const getCommunes = async (id) => {
  const tenantId = localStorage.getItem("tenant");
  const tenantBUs = await getTenantBUs(tenantId);
  const bu = tenantBUs[0].businessUnitUUID;
  localStorage.setItem("bu", bu);
  const url = `${API_URL_BFF}/communes/city/${id}/business-unit/${bu}`;
  try {
    const resp = await api.get(url);
    return resp.data;
  } catch (e) {
    return { error: e, res: null };
  }
};

export const getIdentificationTypes = async () => {
  const tenantId = localStorage.getItem("tenant");
  const tenantBUs = await getTenantBUs(tenantId);
  const bu = tenantBUs[0].businessUnitUUID;
  localStorage.setItem("bu", bu);
  const url = `${API_URL_BFF}/identification-types/business-unit/${bu}`;
  try {
    const resp = await api.get(url);
    return resp.data;
  } catch (e) {
    return { error: e, res: null };
  }
};
