import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "0px",
    background: "#FAFAFA",
    marginTop: "16px",
    padding: "16px",
  },
  containerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  font500: {
    fontWeight: "500",
  },
}));
