import { api } from "../../../util/Api";
const API_URL_BFF = process.env.REACT_APP_BFF_URL;

export const getDocumentTypes = async () => {
  const tenantId = localStorage.getItem("tenant");
  const url = `${API_URL_BFF}/documentations/tenant/${tenantId}`;
  try {
    const resp = await api.get(url);
    return resp.data;
  } catch (e) {
    return { error: e, res: null };
  }
};
