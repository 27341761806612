export const colors = {
  primaryColor: "#00269E",
};

export const titles = {
  dialogTitle: `Agregar teléfono adicional`,
  dialogTitleEdit: `Editar teléfono adicional  `,
  acceptTitle: `Aceptar`,
  cancelTitle: `Cancelar`,
  textPhone: `Teléfono adicional`,
  bodyTitle: `Números de teléfono`,
  agregarTelefonoTitle: `agregar  teléfono`,
  saveTitle: `Guardar`,
};
