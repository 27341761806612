export const clean = (rut) => {
  return typeof rut === "string"
    ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
    : "";
};
export const transparentColor = (color, opacity) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};
export const format = (rut) => {
  rut = clean(rut);

  let result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
  for (let i = 4; i < rut.length; i += 3) {
    result = rut.slice(-3 - i, -i) + "." + result;
  }

  return result;
};

export const TEXT_REGEXP =
  "^[a-zA-Zá-úÁ-ÚñÑ][a-zA-Zá-úÁ-ÚñÑ1-9.,-]+(\\s[a-zA-Zá-úÁ-ÚñÑ1-9.,-]+)*\\s?$";
export const NAME_REGEXP =
  "^[a-zA-Zá-úÁ-ÚñÑ][a-zA-Zá-úÁ-ÚñÑ1-9.-]+(\\s[a-zA-Zá-úÁ-ÚñÑ1-9.-]+)*\\s?$";
export const INPUT_REGEXP = "[a-zA-ZñÑáéíóúÁÉÍÓÚs. -]+";
export const NUM_REGEXP = "^([0-9]+)$";
