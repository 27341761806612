import { floor, log, pow } from "mathjs";

export const convertHexToRGBColor = (h, opacity = 1.0) => {
  let r = 0,
    g = 0,
    b = 0;
  if (h && h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];
  } else if (h && h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  } else return "";
  return "rgb(" + +r + "," + +g + "," + +b + "," + opacity + ")";
};

export const transparentColor = (color, opacity) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const appContext = "providers.postulation.";

export const parseFileWeight = (weight) => {
  if (!weight) return `0 MB`;
  const k = 1024;
  const decimals = 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = floor(log(weight) / log(k));

  return `${parseFloat((weight / pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
};
