import { useState } from "react";
import { useForm } from "react-hook-form";

export default function ViewModelHome() {
  const [activeStep, setActiveStep] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting, isValid },
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      country: null,
      city: null,
      commune: null,
    },
  });

  const onRefresh = () => {
    setRefreshPage(!refreshPage);
  };

  const onClickBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClickCancel = () => {
    setActiveStep(0);
    window.location.reload(false);
  };

  const onClickNext = (data) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return {
    onClickBack,
    onClickCancel,
    onClickNext,
    activeStep,
    control,
    watch,
    handleSubmit,
    setValue,
    isDirty,
    isSubmitting,
    isValid,
    onRefresh,
    refreshPage,
    setRefreshPage,
  };
}
