import React, { useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { inputTypes, componentTypes, variantTypes } from "../../util/constants";
import { useStyles } from "./styles";

const maxFileSize = 10000000; // 10 MB
const allowFormats = ["JPEG", "JPG", "PNG", "PDF"];

export const UploadButtonMultiple = ({
  text,
  variant,
  data,
  setData,
  formats = ".jpg,.png,.pdf,.jpeg",
  color,
  disabled,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleError = () => {
    setData({
      ...data,
      error: true,
    });
    setLoading(false);
    return;
  };

  const handleFileUpload = (e) => {
    setLoading(true);

    // Validar que se cargue archivo
    if (!e.target.files) return handleError();
    const file = e.target.files[0];

    // Validar que tenga nombre
    if (!file.name) return handleError();
    const splitName = file.name.split(".");
    const extension = splitName[splitName.length - 1].toUpperCase();

    // Validar tamaño del archivo
    if (file.size > maxFileSize || !allowFormats.includes(extension))
      return handleError();

    setData({
      file,
      filename: file.name,
      fileWeight: file.size,
      error: false,
    });
    setLoading(false);
    e.target.value = null; // Limpia el valor del input
  };

  return (
    <Button
      component={componentTypes.label}
      variant={variant || variantTypes.contained}
      color={color ? color : ""}
      disabled={disabled}
    >
      {loading ? (
        <CircularProgress size={16} className={classes.circularProgress} />
      ) : (
        text
      )}
      <input
        type={inputTypes.file}
        hidden
        onChange={handleFileUpload}
        accept={formats}
      />
    </Button>
  );
};
