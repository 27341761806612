import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useStyles } from "./styles";
import { LanguageSelector } from "../components/LanguageSelector";
import { LogoTuten } from "../components/LogoTuten";
import { PageTitle } from "./postulation/components/PageTitle";
import { CustomStepper } from "./postulation/components/CustomStepper";
import useViewModel from "./viewModel";
import { BasicData } from "./postulation/BasicData/index";
import { Documentation } from "./postulation/Documentation";
import { CostsAndZones } from "./postulation/CostsAndZones";
import { Successfull } from "./postulation/Successfull";
import { TenantSelector } from "../components/TenantSelector";
import { PostulationContext } from "../util/postulationContext";

export const Home = () => {
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false);
  const showLanguageSelector = false;
  const [globalData, setGlobalData] = useState({});

  const {
    onClickBack,
    onClickCancel,
    onClickNext,
    activeStep,
    onRefresh,
    refreshPage,
    setRefreshPage,
  } = useViewModel();

  return (
    <PostulationContext.Provider value={[globalData, setGlobalData]}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <LogoTuten />
        {activeStep === 0 && <TenantSelector onRefresh={onRefresh} />}
        {showLanguageSelector && <LanguageSelector />}
      </Box>
      {activeStep === 2 && (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Successfull />
        </Box>
      )}
      {activeStep !== 2 && (
        <Box
          style={{ marginLeft: "76px", marginRight: "76px", marginTop: "44px" }}
        >
          <Box>
            <PageTitle />
            <CustomStepper activeStep={activeStep} />
          </Box>
          {activeStep === 0 && (
            <BasicData onClickNext={onClickNext} refreshPage={refreshPage} />
          )}
          {activeStep === 1 && (
            <Documentation
              onClickNext={onClickNext}
              onClickBack={onClickBack}
              onClickCancel={onClickCancel}
            />
          )}
          {activeStep === 9999 && (
            <CostsAndZones
              onClickNext={onClickNext}
              onClickBack={onClickBack}
              onClickCancel={onClickCancel}
            />
          )}
        </Box>
      )}
    </PostulationContext.Provider>
  );
};
