import { useState } from "react";
//import { getBranchAditionalPhones } from '../services/AditionalPhonesServices'
//import { editSucursal, getBranchShort } from '../../../../../services/sucursals'
import { cleanData } from "../utils/utils";
import { forEach } from "lodash";

export default function EditAditionalPhonesViewModel() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  /**
   * Buscar los datos de los telefonos de la sucursal
   * @param {*} id de la sucursal
   */
  async function getData(id) {
    //const { res, error } = await getBranchAditionalPhones(id);
    //(error);
    //setData(res);
    const res = JSON.parse(localStorage.getItem("phones"));
    setData(res);
    setIsLoading(false);
  }

  async function getDataNew() {
    const res = [{ id: "T0", phone: "" }];
    setData(res);
  }

  /**
   * Agregar un nuevo campo telefonico en blanco en la lista de telefonos
   * @param {*} val representa los datos del formulario
   */
  async function onClickToAdd(val) {
    const arr1 = cleanData(val);
    const next = "T".concat(arr1.length + 1);
    const newArr = [
      ...arr1,
      { id: next, phone: localStorage.getItem("phoneCode") },
    ];
    console.log("newArr", newArr);
    setData(newArr);
  }

  /**
   * Eliminar un telefono de la lista
   * @param {*} index
   * @param {*} arr
   * @param {*} arrDeleted
   */
  async function onClickToDelete(val, index) {
    const arr = cleanData(val);
    arr.splice(index, 1);
    const newArr = [...arr];
    setData(newArr);
  }

  /**
   * Guardar los telefonos en la sucursal
   * @param {*} arr
   * @param {*} sucursalId
   */
  async function save(arr, sucursalId) {
    for (let index = 0; index < arr.length; index++) {
      arr[index].id = index;
    }
    localStorage.setItem("phones", JSON.stringify(arr));
    // const sucursal = await getBranchShort(sucursalId) //buscar el objeto sucursal
    //sucursal.phones = [...arr] //sustituir el arreglo de telefonos adicionales por los recibidos
    //await editSucursal(sucursal, sucursalId) // actualizar los datos de la sucursal
  }

  return {
    error,
    getData,
    isLoading,
    data,
    onClickToAdd,
    onClickToDelete,
    save,
    getDataNew,
  };
}
