import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  containerDialog: {
    paddingLeft: '22px',
    paddingRight: '22px',
    marginTop: '2px',
    marginBottom: '10px',
  },
}))
