import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { InputPropsDefault } from "../styles";
import PropTypes from "prop-types";
import { useStyles } from "./styles";

const InputText = ({
  value,
  error,
  readOnly,
  endAdornment,
  startAdornment,
  shrink,
  variant,
  InputProps,
  maxLength,
  minLength,
  multiline,
  minRows,
  maxRows,
  required,
  type,
  precision,
  showHelperText,
  ...restOfProps
}) => {
  const classes = useStyles();
  const [validation, setValidation] = useState({
    value: false,
    info: false,
    message: "",
  });
  const maxLengthByType =
    maxLength !== undefined && type === "email" ? 50 : maxLength;
  const VALIDATION_DEFAULT_VALUE = { value: false, message: "" };

  useEffect(() => {
    if (value.length > 0) {
      validateMinLength();
      validateEmailPattern();
    } else {
      setValidation(VALIDATION_DEFAULT_VALUE);
    }
  }, [value]);

  function parsePrecision(paramPrecision) {
    try {
      return parseInt(paramPrecision);
    } catch (e) {
      return 0;
    }
  }

  function validateMinLength() {
    if (value.length < minLength) {
      setValidation({
        value: true,
        message: `Ingresa mín. ${minLength} caracteres.`,
      });
    } else {
      setValidation(VALIDATION_DEFAULT_VALUE);
    }
  }

  function validateMaxLength() {
    if (value.length >= maxLengthByType && !validation.value) {
      setValidation({
        info: true,
        message: `Ingresa máx. ${maxLengthByType} caracteres.`,
      });
    } else if (value.length < maxLengthByType && !validation.value) {
      setValidation(VALIDATION_DEFAULT_VALUE);
    }
  }

  function validateEmailPattern() {
    if (type === "email" && /\S+@\S+\.\S+/.test(value) && !validation.info) {
      setValidation(VALIDATION_DEFAULT_VALUE);
    } else if (type === "email" && !/\S+@\S+\.\S+/.test(value)) {
      setValidation({ value: true, message: "Email no válido." });
    }
  }

  return (
    <TextField
      {...restOfProps}
      style={{ height: "-webkit-fill-available" }}
      value={value}
      variant={variant}
      error={!!error || validation.value}
      InputLabelProps={{ ...InputPropsDefault(), shrink }}
      InputProps={{
        readOnly: readOnly,
        endAdornment: endAdornment,
        startAdornment: startAdornment,
        classes: { root: classes.inputRoot },
        ...InputProps,
      }}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      required={required}
      multiline={multiline}
      minRows={minRows}
      maxRows={maxRows}
      inputProps={{
        maxLength: maxLengthByType,
        minLength: required ? minLength : 0,
      }}
      onKeyPress={(e) => {
        if (e.key !== "Enter") {
          validateMaxLength();
        }
      }}
      onKeyDown={(e) => {
        if (type === "number" && e.keyCode !== 8) {
          const precisionValue = parsePrecision(precision);

          if (precisionValue > 0) {
            const regexPrecision =
              "^\\d{1,}(\\,\\d{1," + precisionValue + "})?$";

            const testValue = value + e.key + (e.key === "," ? "0" : "");

            !RegExp(regexPrecision).test(testValue) && e.preventDefault();
          } else {
            !RegExp("^([0-9]+)$").test(e.key) && e.preventDefault();
          }
        }
      }}
      helperText={
        showHelperText ? (
          <>
            <span className={classes.p}>
              {error
                ? error.message
                : validation.value || (validation.info && !multiline)
                ? validation.message
                : null}
            </span>
            <span className={classes.p}>
              {multiline && `${value.length}/${maxLengthByType}`}
            </span>
          </>
        ) : null
      }
    />
  );
};

InputText.defaultProps = {
  variant: "outlined",
  multiline: false,
  minRows: 3,
  maxRows: 5,
  required: false,
  minLength: 1,
  maxLength: 30,
  readOnly: false,
  type: "text",
  shrink: true,
  showHelperText: true,
};

InputText.propTypes = {
  readOnly: PropTypes.bool,
  shrink: PropTypes.bool,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  required: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  showHelperText: PropTypes.bool,
};

export { InputText };
