import { useState, useEffect } from "react";
import { getDocumentTypes } from "./services";

export default function ViewModelDocumentation() {
  const [documentTypes, setDocumentTypes] = useState([]);
  const getDoumentationType = async (id) => {
    const response = await getDocumentTypes(id);
    const res = response.map((item) => {
      return {
        ...item,
        tipo: item.documentationName === "Fotos Unidades" ? 1 : 2,
      };
    });
    setDocumentTypes(res);
  };

  useEffect(() => {
    getDoumentationType();
  }, []);

  return { documentTypes };
}
