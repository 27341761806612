import React, { useState, useRef, useEffect } from "react";
import {
  styled,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  CircularProgress,
} from "@material-ui/core";
import { useStyles } from "./styles";
import BootstrapDialogTitle from "./BootstrapDialogTitle";
import { MaterialButton } from "../Buttons";
import {
  classesNames,
  componentTypes,
  stylesConstants,
  variantTypes,
  typeofValue,
} from "../../util/constants";

const BootstrapDialog = styled(Dialog)(({ theme, st }) => {
  return {
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiDialog-paperWidthSm": {
      width: `${st.width} ${stylesConstants.important}`,
      maxWidth: `${stylesConstants.none} ${stylesConstants.important}`,
      height: `${st.height} ${stylesConstants.important}`,
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(0),
    },
    "& .MuiBackdrop-root": {
      backgroundColor: stylesConstants.backgroundColorDialog,
    },
  };
});

const DialogComponent = ({
  open,
  setOpen,
  title,
  msg,
  buttons,
  modalSize,
  disabledTitle,
  styles,
  handleClose,
}) => {
  const classes = useStyles();
  const [scroll] = useState(variantTypes.paper);
  const [buttonsProp, setButtonsProp] = useState([]);
  const [loadingButtons, setLoadingButtons] = useState(true);
  const handleCloseDefault = handleClose
    ? handleClose
    : () => {
        setOpen(false);
      };
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }

      const buttonsArr = [];
      buttons?.forEach((btn, i) => {
        buttonsArr.push({
          index: i,
          disabled: btn.disabled || false,
          disabledAfterClick: btn.disabledAfterClick,
        });
      });
      setButtonsProp(buttonsArr);
      setLoadingButtons(false);
    }
  }, [open]);

  const disabledButton = (index) => {
    const buttonsAux = [];
    buttonsProp.forEach((btn, i) => {
      buttonsAux.push({
        index: i,
        disabled: index == i && btn.disabledAfterClick ? true : btn.disabled,
        disabledAfterClick: btn.disabledAfterClick,
      });
    });
    setButtonsProp(buttonsAux);

    const enableButtonAux = [];

    setTimeout(() => {
      buttonsProp.forEach((btn, i) => {
        enableButtonAux.push({
          index: i,
          disabled: index == i && btn.disabledAfterClick ? false : btn.disabled,
          disabledAfterClick: btn.disabledAfterClick,
        });
      });
      setButtonsProp(enableButtonAux);
    }, 3500);
  };

  const isDisabledButton = (btnDisabled, buttonsPropDisabled) => {
    return typeof btnDisabled === typeofValue.boolean
      ? btnDisabled
      : buttonsPropDisabled;
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleCloseDefault}
        aria-labelledby={classesNames.customizedDialogTitle}
        open={open}
        st={{
          width: modalSize || stylesConstants.fitContent,
          height: styles?.height || stylesConstants.fitContent,
        }}
      >
        {!disabledTitle && (
          <BootstrapDialogTitle
            id={classesNames.customizedDialogTitle}
            handleClose={handleCloseDefault}
          >
            {title}
          </BootstrapDialogTitle>
        )}
        <DialogContent
          dividers={scroll === variantTypes.paper}
          style={{
            color: stylesConstants.rgbStandard,
            width: stylesConstants.standardPercentage,
          }}
        >
          <DialogContentText
            id={classesNames.scrollDialogDescription}
            ref={descriptionElementRef}
            tabIndex={-1}
            component={componentTypes.div}
            style={{
              color: stylesConstants.rgbStandard,
              width: stylesConstants.standardPercentage,
              margin: stylesConstants.auto,
              maxWidth: `${stylesConstants.standardPercentage} ${
                stylesConstants.important
              }`,
            }}
          >
            <div style={{ width: stylesConstants.auto }}>{msg}</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            paddingTop: stylesConstants.sixteenPixels,
            paddingBottom: stylesConstants.sixteenPixels,
          }}
        >
          {!loadingButtons &&
            buttons?.map((btn, i) => (
              <MaterialButton
                text={
                  btn.loading ? (
                    <CircularProgress
                      size={16}
                      className={classes.circularProgress}
                    />
                  ) : (
                    btn.text
                  )
                }
                onClick={() => {
                  btn.action();
                  disabledButton(i);
                }}
                key={i}
                variant={btn.variant}
                hostName={btn.hostName}
                disabled={isDisabledButton(
                  btn.disabled,
                  buttonsProp[i].disabled
                )}
                overrideClasses={`${classes.buttons} ${
                  btn?.overrideButtonStyle
                }`}
              />
            ))}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default DialogComponent;
