import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useTranslation } from "react-i18next";
import { steps } from "./constants";

export const CustomStepper = (props) => {
  const { activeStep } = props;
  const { t } = useTranslation();
  return (
    <Stepper
      activeStep={activeStep}
      style={{
        paddingLeft: "0px",
        paddingRight: "20px",
        backgroundColor: "transparent",
      }}
    >
      {steps(t).map((label) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
