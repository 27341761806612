export const translationKeys = {
  basic_data: "Datos básicos",
  add_aditional_phones: "Agregar teléfono Adicional",
  bussinesname: "Razón social",
  operativename: "Nombre operativo",
  dni: "[CUIT/CUIL] [NIT/RUT] [RFC]",
  economicactivity: "Rubro/Actividad",
  activityname: "Nombre actividad/rubro",
  workers: "Cantidad trabajadores",
  mobileunits: "Cantidad de unidades móviles",
  address: "Dirección",
  country: "País",
  city: "[Provincia] [Departamento] [Entidad]",
  commune: "[Localidad] [Municipio/Ciudad] [Municipio]",
  contact_information: "Información de contacto de administración",
  firstname: "Nombre",
  lastname: "Apellidos",
  email: "Correo electrónico",
  channelphone: "Teléfono canal",
  contactphone: "Teléfono contacto",
  next: "CONTINUAR",
  cancel: "Cancelar",
};

export const keywords = {
  bussinesname: "bussinesname",
};

export const internationalPhoneCodes = [
  { tenant: "adff7f6a-e97d-11eb-9a03-0242ac130003", code: "+52" },
  { tenant: "ddaa614e-6514-11ea-4421-0242ac139997", code: "+54" },
  { tenant: "d47bd0c6-1512-11ef-9633-0242ac130002", code: "+57" },
];
