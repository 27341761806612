import { useState } from "react";
//import { getBranchAditionalPhones } from '../services/AditionalPhonesServices'

export default function ListAditionalPhonesViewModel() {
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(0);

  async function getData(id) {
    //const { res, error } = await getBranchAditionalPhones(id)
    //setError(error)
    const res = JSON.parse(localStorage.getItem("phones"));
    if (res !== null) {
      const res1 = res.map((item) => {
        const allPhone = item.phone;
        const areacode = allPhone.substring(0, 2);
        const tel = allPhone.substring(2, allPhone.length);
        const phone = "(+"
          .concat(areacode)
          .concat(")")
          .concat(" ")
          .concat(tel);
        return { ...item, formatPhone: phone };
      });

      setData(res1);
      setSize(res.length);
      setIsLoading(false);
    }
  }

  return {
    error,
    getData,
    data,
    isLoading,
    size,
  };
}
