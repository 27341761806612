export const configUser = {
  apac: {
    theme: {
      palette: {
        primary: { main: "#111", light: "#F1F8FF", contrastText: "#fff" },
      },
    },
  },
};

export const defaultTheme = {
  palette: {
    primary: { main: "#002FC5", light: "#F1F8FF", contrastText: "#002FC5" },
    background: {
      default: "#F8F8F8",
    },
  },
  spacing: 6,
  typography: {
    h3: {
      fontSize: "48px",
      lineHeight: "56px",
      fontWeight: "normal",
    },
    h4: {
      fontSize: "34px",
      lineHeight: "40px",
    },
    h5: {
      fontSize: "24px",
      lineHeight: "36px",
    },
    h6: {
      fontSize: "20px",
      lineHeight: "23px",
      fontWeight: "normal",
    },
    body1: {
      fontSize: "14px",
      lineHeight: "28px",
    },
    body2: {
      fontSize: "14px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.6)",
    },
    body3: {
      fontSize: "16px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.6)",
    },
    body4: {
      fontSize: "12px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.6)",
    },
    button: {
      fontSize: "14px",
    },
    moduleTitle: {
      fontFamily: "Roboto",
      fontSize: "22px",
      textAlign: "left",
      margin: "0",
      padding: "0",
      fontWeight: 400,
      color: "black",
      fontStyle: "normal",
      lineHeight: "29px",
      letterSpacing: 0,
    },
  },
};
