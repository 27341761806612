import React from "react";
import { Grid } from "../../../node_modules/@material-ui/core/index";
import { makeStyles } from "../../../node_modules/@material-ui/styles/index";
import { TextField } from "../../../node_modules/@material-ui/core/index";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  useStylesInputLabel,
} from './inputPropsDefault'

import { inputPropsDefault } from './inputPropsDefault'

export const suggestionTypes = {
  suggestion1: "suggestion1",
  suggestion2: "suggestion2",
  suggestion3: "suggestion3",
};

type ChangeEventHandler = React.ChangeEventHandler<
  HTMLInputElement | HTMLTextAreaElement
>;

interface TextProps {
  id: any;
  required: boolean;
  name: string;
  placeholder: string;
  label: string;
  value: any;
  error: any;
  helperText: string;
  onChange: ChangeEventHandler;
  setAddress: ChangeEventHandler;
  isModal: boolean;
  inputRef: any;
  suggestionType: string;
}

const useStyles: Function = makeStyles((theme) => ({
  sugerencias: {
    position: "absolute",
    zIndex: 2,
    border: 1,
    width: "56%",
    "@media(maxWidth: 959px)": {
      width: "100% !important",
    },
  },
  sugerencias2: {
    position: "absolute",
    zIndex: 2,
    width: "56%",
    "@media(maxWidth: 959px)": {
      width: "100% !important",
    },
    border: "solid #bbbbbb 1px",
    borderRadius: 5,
    overflow: "hidden",
    boxShadow: "5px 5px 6px 1px #4c4c4c52",
    marginTop: -120,
  },
  sugerencias3: {
    position: "absolute",
    zIndex: 2,
    width: "27%",
    "@media(maxWidth: 959px)": {
      width: "100% !important",
    },
    "@media(min-width: 1200px)": {
      maxWidth: "370px !important",
    },
    border: "solid #bbbbbb 1px",
    borderRadius: 5,
    overflow: "hidden",
    boxShadow: "5px 5px 6px 1px #4c4c4c52",
    marginTop: -20,
    textAlign: "left",
    backgroundColor: "#fff",
  },
  sugerenciasHidden: {
    display: "none",
  },
  item: {
    padding: 15,
  },
  asterikColor: {
    color: "#f44336",
  },
  textClass: {
    height: "1rem",
    visibility: "hidden",
  },
  textClassError: {
    height: "1rem",
  },
  rootLabel: {
    color: "rgba(0, 0, 0, 0.75) !important",
  },
  rootInput: {
    "& .MuiInputBase-input": {
      padding: "18.5px 14px",
    },
  },
}));
export default function TextPlacesAutoComplete(props: TextProps): JSX.Element {
  const classes = useStyles();
  const classesInput = useStylesInputLabel();

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value);
    const arrAdressLen = results[0].address_components.length;
    if (props.onChange) {
      var values: any = {
        value: value,
        lat: (await getLatLng(results[0])).lat,
        lng: (await getLatLng(results[0])).lng,
        country: results[0].address_components[arrAdressLen - 1].long_name,
        city: results[0].address_components[arrAdressLen - 2].long_name,
        commune: results[0].address_components[arrAdressLen - 3].long_name,
      };
      props.onChange(values);
    }
  };

  const onChange = async (value: any) => {
    props.setAddress(value);
  };

  const generateClassSuggestion = (suggestions: any, loading: any) => {
    if (suggestions?.length === 0 && !loading) return classes.sugerenciasHidden;
    if (props.suggestionType) {
      if (props.suggestionType == suggestionTypes.suggestion2)
        return classes.sugerencias2;
      if (props.suggestionType == suggestionTypes.suggestion3)
        return classes.sugerencias3;
      return classes.sugerencias;
    }
    if (props.isModal) return classes.sugerencias2;
    return classes.sugerencias;
  };
  // @ts-ignore
  return (
    <div>
      <PlacesAutocomplete
        value={props.value}
        onChange={onChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              id={props.id}
              fullWidth
              variant="outlined"
              label={props.label}
              required={props.required}
              placeholder={props.placeholder}
              {...getInputProps({})}
              InputLabelProps={{
                ...inputPropsDefault(classesInput),
              }}
              inputProps={{
                maxLength: 100,
              }}
              InputProps={{ classes: { root: classes.rootInput } }}
              error={!!props.error}
              helperText={
                <span
                  className={
                    props.error ? classes.textClassError : classes.textClass
                  }
                >
                  {props.helperText}
                </span>
              }
              inputRef={props.inputRef}
            />
            <Grid
              className={generateClassSuggestion(suggestions, loading)}
              item={true}
              md={7}
              xs={12}
            >
              {loading ? <div>...loading</div> : null}
              {suggestions.map((suggestion) => {
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      padding: "10px",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      padding: "10px",
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={suggestion.placeId}
                    style={{backgroundColor:'#FFFFFF',padding:'10px',cursor:'pointer',color:'#000000',opacity:'0.87',fontFamily:'Roboto', fontSize:'14px'}}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </Grid>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
