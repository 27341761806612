import React, { useEffect, useState } from "react";
import { Typography, Grid, IconButton, Tooltip, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../constants";
import { useStyles } from "../styles";
import InfoIcon from "@material-ui/icons/Info";
import { useForm } from "react-hook-form";
import { UploadButtonMultiple } from "../../../../components/UploadButtonMultiple";
import { ShowFilesNames } from "./showFilesNames";

export const UploadImages = ({ item, updateDocs }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting },
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const handleDeleteFile = (idx) => {
    setIsDeleting(true);
    let newData = [...data];
    newData.splice(idx, 1);
    if (newData.length <= 4) {
      setDisabled(false);
    }
    setData(newData);
    updateDocs({ ...newData, evento: "delete" });
    //setIsDeleting(false);
  };

  const addImage = (newItem) => {
    setIsDeleting(false);
    if (data.length === 4) {
      setDisabled(true);
    }
    setData([...data, newItem]);
  };

  useEffect(() => {
    if (data.length > 0) {
      const doc = {
        tipo: item.tipo,
        data: data,
        evento: "add",
        documentationName: item.documentationName,
      };
      if (!isDeleting) {
        updateDocs(doc);
      }
    }
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        <Typography className={classes.normaltext}>
          {item.documentationName}
          <span className={classes.normaltextRed}> *</span>
        </Typography>
      </Grid>
      {data.length === 0 && (
        <Grid item xs={6}>
          <Box className={classes.fileBox}>
            <Typography className={classes.noFileText}>
              {"No hay archivos seleccionados"}
            </Typography>
          </Box>
        </Grid>
      )}
      {data.map((item, idx) => {
        return (
          <ShowFilesNames
            data={item}
            idx={idx}
            key={idx}
            handleDeleteFile={handleDeleteFile}
            len={data.length}
          />
        );
      })}

      <Grid item xs={data.length === 0 ? 6 : data.length === 1 ? 6 : 4}>
        <div style={{ paddingLeft: "21px" }}>
          {disabled && (
            <Tooltip
              title={
                <Typography className={classes.tooltip}>
                  {t(translationKeys.alertMaxFiles)}
                </Typography>
              }
              aria-label="add"
              placement="right"
              arrow
            >
              <span>
                <UploadButtonMultiple
                  data={data}
                  setData={addImage}
                  text={"Seleccionar archivos"}
                  variant={"outlined"}
                  color={"primary"}
                  formats=".jpg,.png,.pdf"
                  disabled={disabled}
                />
              </span>
            </Tooltip>
          )}
          {!disabled && (
            <UploadButtonMultiple
              data={data}
              setData={addImage}
              text={"Seleccionar archivos"}
              variant={"outlined"}
              color={"primary"}
              formats=".jpg,.png,.pdf"
            />
          )}

          <Tooltip
            title={
              <Typography className={classes.tooltip}>
                {t(translationKeys.alertInfoImage)}
              </Typography>
            }
            aria-label="add"
            placement="right"
            arrow
          >
            <IconButton aria-label="delete">
              <InfoIcon style={{ color: "#2196F3" }} fontSize="medium" />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
    </>
  );
};
