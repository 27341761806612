import { useContext, useEffect, useState } from "react";
import {
  getCities,
  getCommunes,
  getCountries,
  getIdentificationTypes,
  getEconomicActivity,
} from "./services";
import { useForm } from "react-hook-form";
import { removeAccents } from "../../../util/commons";
import { PostulationContext } from "../../../util/postulationContext";
export default function ViewModelBasicData(onClickNext, refreshPage) {
  const [countries, setCountries] = useState([]);
  const [economicActivity, setEconomicActivity] = useState([]);
  const [cities, setCities] = useState([]);
  const [comunes, setComunes] = useState([]);
  const [identificationType, setIdentificationType] = useState([]);
  const info = JSON.parse(localStorage.getItem("basicdata"));
  const validos = ["NIT/RFC Homologado", "CUIT", "CUIL", "NIT"];
  const [identificationTypeText, setIdentificationTypeText] = useState("");
  const [cityLabel, setCityLabel] = useState("");
  const [communeLabel, setCommuneLabel] = useState("");
  const [defaultPhoneCode, setDefaultPhoneCode] = useState("");
  const [globalData, setGlobalData] = useContext(PostulationContext);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting, isValid },
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      country: null,
      city: null,
      commune: null,
      economicActivity: null,
    },
  });

  const selectedAddress = watch("address");
  const selectedCountry = watch("country");
  const selectedCity = watch("city");

  const init = async () => {
    await getEconomicActivityInfo();
    await getCountriesInfo();
    await getIdentificationTypeInfo();
    getSpecificLabels();
    validateCachedData();
  };

  const validateCachedData = () => {
    if (info) {
      setValue("bussinesname", info.bussinesname);
      setValue("operativename", info.operativename);
      setValue("dni", info.dni);
      setValue("economicActivity", info.economicActivity);
      setValue("workers", info.workers);
      setValue("mobileunits", info.mobileunits);
      setValue("address", info.address);
      setValue("country", info.country);
      setValue("city", info.city);
      setValue("commune", info.commune);
      setValue("firstname", info.firstname);
      setValue("lastname", info.lastname);
      setValue("email", info.email);
      setValue("channelphone", info.channelphone);
      setValue("contactphone", info.contactphone);
    }
  };

  const getSpecificLabels = () => {
    const tenantId = localStorage.getItem("tenant");
    if (tenantId === "adff7f6a-e97d-11eb-9a03-0242ac130003") {
      setCityLabel("Entidad");
      setCommuneLabel("Municipio");
    }
    if (tenantId === "ddaa614e-6514-11ea-4421-0242ac139997") {
      setCityLabel("Provincia");
      setCommuneLabel("Localidad");
    }
    if (tenantId === "d47bd0c6-1512-11ef-9633-0242ac130002") {
      setCityLabel("Departamento");
      setCommuneLabel("Municipio/Ciudad");
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    init();
  }, [refreshPage]);

  const onSummit = (data) => {
    localStorage.setItem("basicdata", JSON.stringify(data));
    localStorage.setItem("email", data.email);
    setGlobalData(data);
    onClickNext();
  };

  const getIdentificationTypeInfo = async () => {
    const response = await getIdentificationTypes();
    const list = (response.items || [])
      .filter((item) => {
        return validos.contains(item.name);
      })
      .map((item) => {
        return {
          ...item,
          label:
            item.name === "NIT/RFC Homologado"
              ? "RFC"
              : item.name === "NIT"
              ? "NIT/RUT"
              : item.name,
          value: item.id,
        };
      });
    const res = list.map((item) => {
      return item.label;
    });

    setIdentificationTypeText(res.toString().replace(",", "/"));
    setIdentificationType(list);
  };

  const getCountriesInfo = async () => {
    const response = await getCountries();
    const list = (response || []).map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id,
      };
    });
    setCountries(list);
  };
  const getEconomicActivityInfo = async () => {
    const response = await getEconomicActivity();
    const list = (response || []).map((item) => {
      return {
        ...item,
        label: item.description,
        value: item.id,
      };
    });
    setEconomicActivity(list);
  };

  const getCitiesInfo = async (id) => {
    const response = await getCities(id);
    const list = (response || []).map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id,
      };
    });
    setCities(list);
  };

  const getCommunesInfo = async (id) => {
    const response = await getCommunes(id);
    const list = (response || []).map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id,
      };
    });
    setComunes(list);
  };

  useEffect(() => {
    if (selectedCountry) {
      setValue("city", null);
      setValue("commune", null);
      getCitiesInfo(selectedCountry.id);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCity) {
      setValue("commune", null);
      getCommunesInfo(selectedCity.id);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedAddress && info === null) {
      if (selectedAddress.hasOwnProperty("country")) {
        getCountriesInfo();
      }
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (comunes.length > 0) {
      if (selectedAddress && selectedAddress.hasOwnProperty("commune")) {
        const selectCommune = comunes.find(
          (item) =>
            item.name.toLowerCase() ===
            removeAccents(selectedAddress.commune).toLowerCase()
        );
        if (selectCommune) {
          setValue("commune", selectCommune);
        } else {
          setValue("commune", null);
        }
      }
      if (info) {
        setValue("commune", info.commune);
      }
    }
  }, [comunes]);

  useEffect(() => {
    if (cities.length > 0) {
      if (selectedAddress && selectedAddress.hasOwnProperty("city")) {
        const selectCity = cities.find(
          (item) =>
            item.name.toLowerCase() === selectedAddress.city.toLowerCase()
        );
        if (selectCity) {
          setValue("city", selectCity);
        } else {
          setValue("city", null);
          setValue("commune", null);
        }
      }
      if (info) {
        setValue("city", info.city);
      }
    }
  }, [cities]);

  useEffect(() => {
    if (countries.length > 0) {
      if (selectedAddress && selectedAddress.hasOwnProperty("country")) {
        const selectCountry = countries.find(
          (item) =>
            item.name.toLowerCase() === selectedAddress.country.toLowerCase()
        );

        if (selectCountry) {
          setValue("country", selectCountry);
        } else {
          setValue("country", null);
          setValue("city", null);
          setValue("commune", null);
        }
      } else {
        setValue("country", null);
        setValue("city", null);
        setValue("commune", null);
      }
    }
  }, [countries]);

  useEffect(() => {}, []);

  const getIsDisabled = () => {
    if (info) {
      return false;
    }
    if (!isDirty || !isValid) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (info === null) {
      setValue("channelphone", localStorage.getItem("phoneCode"));
      setValue("contactphone", localStorage.getItem("phoneCode"));
    }
  }, [localStorage.getItem("phoneCode")]);

  Array.prototype.contains = function (obj) {
    return this.indexOf(obj) > -1;
  };

  const onClickCancel = () => {
    setShowCancelModal(true);
  };

  return {
    countries,
    economicActivity,
    cities,
    comunes,
    control,
    watch,
    handleSubmit,
    setValue,
    isDirty,
    isSubmitting,
    isValid,
    onSummit,
    identificationTypeText,
    cityLabel,
    communeLabel,
    getIsDisabled,
    onClickCancel,
    showCancelModal,
    setShowCancelModal,
  };
}
