import { makeStyles } from '@material-ui/core'
const baseRoot = margin => ({
  minWidth: 275,
  margin: margin || '10px 20px 20px 0px !important',
  boxShadow:
    '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
})
export const useStyles = margin => {
  return makeStyles(theme => ({
    root: {
      paddingBottom: 12,
      ...baseRoot(margin),
    },
    rootMod: {
      ...baseRoot(margin),
    },
    bullet: {
      display: 'inline-block',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    margin: {
      margin: theme.spacing(1),
    },
  }))()
}
