import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Box, Typography, Button } from '@material-ui/core'
import { titles, colors } from './constants'
import { useTranslation } from 'react-i18next'

export default function HeaderBox(props) {
  const { onClick } = props
  const { t } = useTranslation()
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography style={{ fontWeight: '500' }}>
        {t(titles.bodyTitle)}
      </Typography>
      <Button
        onClick={onClick}
        style={{ color: colors.primaryColor }}
        startIcon={<AddIcon />}
      >
        <Typography style={{ fontWeight: '500', color: colors.primaryColor }}>
          {t(titles.agregarTelefonoTitle)}
        </Typography>
      </Button>
    </Box>
  )
}
