import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { titles, colors } from './constants'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

export default function ListHeader(props) {
  const { isLoading, onClickToEdit } = props
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box className={classes.containerHeader}>
      <Typography style={{ fontWeight: '500' }}>
        {t(titles.bodyTitle)}
      </Typography>
      {!isLoading && (
        <Button
          onClick={onClickToEdit}
          style={{ color: colors.primaryColor }}
          startIcon={<EditIcon />}
        >
          <Typography style={{ fontWeight: '500', color: colors.primaryColor }}>
            &nbsp;{t(titles.editIconTitle)}
          </Typography>
        </Button>
      )}
    </Box>
  )
}
