import React from 'react'
import { Typography, CardContent, Card, Divider } from '@material-ui/core'
import { useStyles } from './styles'

export const SimpleCard = ({
  children,
  title,
  left,
  margin,
  component,
  overrideClassesCard,
  overrideClassesContent,
}) => {
  const classes = useStyles(margin)
  return (
    <Card
      className={`${
        left ? classes.rootMod : classes.root
      } ${overrideClassesCard}`}
    >
      <Typography
        component={component || 'h2'}
        style={{
          fontSize: '18px',
          lineHeight: '160%',
          height: '50px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginLeft: '1rem',
          fontWeight: '500',
          color: 'rgba(0, 0, 0, 0.87)',
        }}
      >
        {title}
      </Typography>
      <Divider />
      {left ? (
        children
      ) : (
        <CardContent
          className={`${classes.cardContent} ${overrideClassesContent}`}
        >
          {children}
        </CardContent>
      )}
    </Card>
  )
}
