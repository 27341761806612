import React from "react";
import { Typography, Grid, IconButton, Tooltip, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../constants";
import { useStyles } from "../styles";
import ClearIcon from "@material-ui/icons/Clear";
import TransitionAlerts from "../../../../components/Alert";
import { parseFileWeight } from "../../../../util/commons";

export const ShowFilesNames = ({ data, idx, handleDeleteFile, len }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onClickDelete = () => {
    handleDeleteFile(idx);
  };

  return (
    <>
      {data?.error == true ? (
        <Grid item xs={6} style={{ translate: "0px -15px" }}>
          <TransitionAlerts
            text={t(translationKeys.alertText)}
            open={data?.error}
            severity={"error"}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={len === 1 ? 6 : 4}>
            <Box className={classes.fileBox}>
              {data?.filename ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title={data.filename} placement="right" arrow>
                    <Typography className={classes.fileText}>
                      {data.filename}
                    </Typography>
                  </Tooltip>
                  <Typography
                    className={`${classes.principalText} ${classes.paddingFileWeigth}`}
                  >
                    ({parseFileWeight(data.fileWeight)})
                  </Typography>
                  <IconButton onClick={onClickDelete}>
                    <ClearIcon fontSize="medium" />
                  </IconButton>
                </div>
              ) : (
                <Typography className={classes.noFileText}>
                  {"No hay archivos seleccionados"}
                </Typography>
              )}
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};
