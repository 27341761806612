import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputText } from "../InputText";

const InputTextController = ({
  name,
  id,
  control,
  defaultValue,
  label,
  placeholder,
  readOnly,
  endAdornment,
  startAdornment,
  shrink,
  variant,
  InputProps,
  maxLength,
  minLength,
  multiline,
  minRows,
  maxRows,
  required,
  requiredText,
  type,
  customValue,
  validate,
  precision,
  customOnChange,
  index,
  showHelperText,
  ...restOfProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <InputText
          id={id}
          label={label}
          value={customValue ? customValue : value}
          onChange={
            customOnChange
              ? (e) => {
                  customOnChange(e, index);
                  onChange(e);
                }
              : onChange
          }
          error={error}
          fullWidth
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          multiline={multiline}
          minRows={minRows}
          maxRows={maxRows}
          required={required}
          type={type}
          readOnly={readOnly}
          variant={variant}
          InputProps={InputProps}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          shrink={shrink}
          precision={precision}
          showHelperText={showHelperText}
          {...restOfProps}
        />
      )}
      rules={{
        required: {
          value: required,
          message: requiredText ? requiredText : `${label} es requerido.`,
        },
        minLength: {
          value: minLength,
          message: `Ingresa mín. ${minLength} caracteres.`,
        },
        validate: validate,
      }}
    />
  );
};

InputTextController.defaultProps = {
  variant: "outlined",
  multiline: false,
  minRows: 3,
  maxRows: 5,
  required: false,
  minLength: 1,
  maxLength: 30,
  readOnly: false,
  type: "text",
  shrink: true,
  defaultValue: "",
  customValue: null,
  validate: null,
  precision: 0,
  showHelperText: true,
};

InputTextController.propTypes = {
  readOnly: PropTypes.bool,
  shrink: PropTypes.bool,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  required: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  id: PropTypes.string,
  showHelperText: PropTypes.bool,
};

export { InputTextController };
