import React, { useEffect } from "react";
import { Phone } from "saas-ui-design";
import DialogComponent from "../../../../../components/dialog/DialogComponent";
import { Box, Grid, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { titles } from "./constants";
import HeaderBox from "./HeaderBox";
import { useStyles } from "./styles";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function PhonesDialog(props) {
  const { data, open, setOpen, onClickAdd, onClickDelete, confirm, kind } =
    props;
  const info = JSON.parse(localStorage.getItem("phones"));
  const classes = useStyles();
  const { handleSubmit, setValue, control } = useForm({
    shouldUnregister: true,
  });

  const handleAddnewPhone = (val) => {
    onClickAdd(val);
  };

  const onSummit = (val) => {
    confirm(val);
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (info === null) {
      if (data) {
        data.map((item, idx) => {
          if (idx === data.length - 1) {
            const name = item.id.toString();
            setValue(name, localStorage.getItem("phoneCode"));
          }
        });
      }
    }
  }, [data]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      title={kind === 0 ? t(titles.dialogTitle) : t(titles.dialogTitleEdit)}
      modalSize={"800px"}
      contentSize={"100%"}
      msg={
        <Box sx={{ flexGrow: 1 }} className={classes.containerDialog}>
          <HeaderBox onClick={handleSubmit(handleAddnewPhone)} />

          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            {data.map((item, index) => {
              const label = t(titles.textPhone)
                .concat(" ")
                .concat(index + 1);
              const name = item.id.toString();
              setValue(name, item.phone);
              return (
                <Grid item xs={4} sm={6} md={6} key={index}>
                  <Box style={{ display: "flex" }}>
                    <Phone
                      key={item.id}
                      name={name}
                      control={control}
                      defaultValue={null}
                      label={label}
                      required={true}
                      localization="es"
                      disableDropdown={true}
                    />
                    {kind === 1 && (
                      <IconButton
                        onClick={handleSubmit((val) =>
                          onClickDelete(val, index)
                        )}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                    {kind === 0 && data.length > 1 && (
                      <IconButton
                        onClick={handleSubmit((val) =>
                          onClickDelete(val, index)
                        )}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      }
      buttons={[
        {
          text: t(titles.cancelTitle),
          action: () => setOpen(false),
          variant: "outlined",
        },
        {
          text: kind === 0 ? t(titles.saveTitle) : t(titles.acceptTitle),
          action: handleSubmit(onSummit),
          variant: "",
        },
      ]}
    />
  );
}
