import { makeStyles } from '@material-ui/core'

// @ts-ignore
export const useStylesInputLabel = makeStyles(theme => ({
  asteriskColor: {
    color: 'rgba(245, 0, 87, 1)',
  },
  cssOutlinedInput: {
    
  },
  cssLabel: {
    fontWeight: "400",
    color: "#000000",
    opacity: "0.75",
  },
  cssFocused: {
    
  },
  cssError: {
    color: `${theme.palette.error.main} !important`,
  },
}))

export const inputPropsDefault = (classes: any) => {
  return {
    shrink: true,
    classes: {
      // @ts-ignore
      asterisk: classes.asteriskColor,
      // @ts-ignore
      root: classes.cssLabel,
      // @ts-ignore
      focused: classes.cssFocused,
      error: classes.cssError,
    },
  }
}

export const hexColorDefault = {
  primaryBlue: '#002FC5', // Azul Primario
}