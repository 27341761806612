import React from "react";
import { Box } from "@material-ui/core";
import Logo from "../../img/tuten.png";

export const LogoTuten = () => {
  return (
    <Box style={{ height: "50px", display: "flex", alignItems: "center" }}>
      <img src={Logo} alt="Logo de tuten" width={145} height={20} />
    </Box>
  );
};
