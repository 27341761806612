import React, { useEffect, useState, useRef } from "react";
import { tenants } from "./constants.js";
import { Select, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
export const TenantSelector = ({ onRefresh }) => {
  const [current, setCurrent] = useState("");
  const prevCountRef = useRef();

  const changeTenant = (value) => {
    setCurrent(value);
  };

  useEffect(() => {
    if (current) {
      localStorage.setItem("tenant", current);
      const res = tenants.find((item) => item.value === current);
      localStorage.setItem("tenantName", res.label);
      localStorage.setItem("phoneCode", res.code);
      localStorage.setItem("id", res.id);
    }
    if (prevCountRef.current && prevCountRef.current !== current) {
      onRefresh();
      prevCountRef.current = current;
    }
  }, [current]);

  useEffect(() => {
    const tenant = localStorage.getItem("tenant");
    if (tenant === undefined || tenant === null) {
      prevCountRef.current = "adff7f6a-e97d-11eb-9a03-0242ac130003";
      setCurrent("adff7f6a-e97d-11eb-9a03-0242ac130003");
    } else {
      setCurrent(tenant);
    }
  }, []);

  return (
    <FormControl>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={current}
        onChange={(e) => changeTenant(e.target.value)}
      >
        {tenants.map((item, idx) => (
          <MenuItem value={item.value} key={idx}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
