import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  subTitle: {
    marginTop: "16px",
    marginBottom: "30px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 175%",
    letterSpacing: " 0.15px",
    opacity: "0.87",
  },
  normaltext: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 143%",
    letterSpacing: " 0.15px",
    opacity: "0.87",
  },
  lastCard: {
    paddingBottom: "0px !important",
  },

  box: {
    background: "rgba(250, 250, 250, 1)",
    padding: "10px",
    width: "100%",
  },
  button: {
    marginRight: "0px !important",
  },
  disabledButton: {
    color: "rgba(0, 0, 0, 0.38) !important",
  },
  cardFooter: {
    marginTop: "0px !important",
  },
  cardBody: {
    marginTop: "20px !important",
  },
  actionButton: {
    maxWidth: "100% !important",
  },
  tooltip: {
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "14px",
    width: "110px",
  },
  tooltipAd: {
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "14px",
    width: "169px",
  },
  fileBox: {
    background: "#FAFAFA",
    marginTop: "5px",
    marginBottom: "5px",
  },
  noFileText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "14px",
    color: "#0000008A",
    padding: "16px 16px",
  },
  fileText: {
    color: "#002FC5",
    fontSize: "14px",
    fontWeight: 500,
    paddingLeft: "16px",
    paddingRight: "16px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
  },
  principalText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    flexShrink: 0,
  },
  padding: {
    paddingBottom: "16px",
    fontSize: "16px !important",
  },
  rightContainer: {
    paddingLeft: "30px",
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "143%",
    letterSpacing: "0.15px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
  },
  paddingLast: {
    paddingTop: "16px",
    fontSize: "16px !important",
  },
  paddingFileWeigth: {
    marginLeft: "5px",
  },
  normaltextRed: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 143%",
    letterSpacing: " 0.15px",
    opacity: "0.87",
    color: "#DC362E",
  },
}));
