// @flow
import React, { useState, createContext } from "react";
import {
  ThemeProvider as ThemeProviderInner,
  createTheme,
} from "@material-ui/core/styles";
import { defaultTheme } from "../configUser";
import { transparentColor } from "../constants/helpers";

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [themeCustom, setThemeCustom] = useState({
    primary: "#002FC5",
    secondary: "#002FC5",
    contrastText: "#FFFFFF",
    highContrast: true,
  });

  const theme = createTheme({
    ...defaultTheme,
    colors: {
      mainDark: "#171737",
      mainLight: "#2A2A65",
      mainBlue: "#00AEC7",
      white: "#fff",
      main: "#0033A0",
      darkBlue: "#299DBA",
      subtitleDark: "rgba(0, 0, 0, 0.87)",
      bluePurple: "#002FC5",
    },
    fonts: {
      mainFont: "Roboto",
    },

    palette: {
      highContrast: themeCustom.highContrast,
      primary: {
        main: themeCustom.primary,
        light: transparentColor(themeCustom.primary, 0.2),
        contrastTextOutLined: themeCustom.highContrast
          ? themeCustom.primary
          : "#000000",
      },
      text: {
        disabled: "rgba(0, 0, 0, 0.38)",
      },
      error: {
        main: "rgba(244, 67, 54, 1)",
      },
      secondary: {
        main: themeCustom.secondary,
      },
    },
    overrides: {
      MuiFormLabel: {
        root: {
          fontSize: "15px",
        },
      },
      MuiInputBase: {
        root: {
          color: "#000000bf",
          opacity: "0.85",
          "&$disabled": {
            "& fieldset.MuiOutlinedInput-notchedOutline": {
              borderStyle: "dotted",
            },
          },
        },
      },
      MuiButton: {
        outlinedPrimary: {
          color: "#000",
        },
        outlinedSizeSmall: {
          color: "#000",
        },
        outlined: {
          color: `${
            themeCustom.highContrast
              ? themeCustom.primary
              : themeCustom.contrastText
          } !important`,
          backgroundColor: "inherit",
          borderColor: `${themeCustom.primary}`,
        },
        contained: {
          color: "#FFFFFF",
          backgroundColor: `${themeCustom.primary}`,
          borderColor: `${themeCustom.primary}`,
          "&:hover": {
            backgroundColor: `${themeCustom.primary}`,
          },
        },
      },
      MuiAutocomplete: {
        listbox: {
          fontSize: "14px",
        },
        noOptions: {
          fontSize: "14px",
          padding: "0px 12px !important",
        },
        tag: {
          backgroundColor: transparentColor(themeCustom.primary, 0.2),
          "&:not(.MuiChip-root)": {
            backgroundColor: "transparent",
          },
        },
        option: {
          '&[data-focus="true"]': {
            backgroundColor: transparentColor(themeCustom.primary, 0.2),
          },
          '&[aria-selected="true"]': {
            backgroundColor: transparentColor(themeCustom.primary, 0.2),
          },
          "&:active": {
            backgroundColor: transparentColor(themeCustom.primary, 0.2),
          },
          fontSize: "14px",
          padding: "0px 12px !important",
          color: "#000000d9",
          opacity: "0.75",
        },
      },
      MuiListItem: {
        gutters: {
          "&:hover": {
            backgroundColor: `${transparentColor(
              themeCustom.primary,
              0.2
            )} !important`,
          },
        },
        root: {
          paddingTop: "2px",
          paddingBottom: "2px",
        },
      },
      MuiPickersDay: {
        day: {
          padding: "0px !important",
          color: "rgba(0, 0, 0, 0.87) !important",
        },
        daySelected: {
          backgroundColor: `${themeCustom.primary} !important`,
          color: "#fff !important",
        },
        current: {
          fontWeight: "600 !important",
          color: `${themeCustom.primary} !important`,
        },
        dayDisabled: {
          color: "rgba(0, 0, 0, 0.38) !important",
        },
      },
      MuiSwitch: {
        switchBase: {
          top: 0,
          left: 0,
          color: "#fafafa",
          zIndex: 1,
          position: "absolute !important",
          transition:
            "left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
          padding: "9px !important",
        },
        colorSecondary: {
          "&.Mui-checked": {
            color: `${themeCustom.primary} !important`,
          },
        },
      },
      MuiBreadcrumbs: {
        ol: {
          '& [class*="MuiButtonBase-root"]': {
            "&:hover": {
              backgroundColor: `${transparentColor(
                themeCustom.primary,
                0.2
              )} !important`,
              borderRadius: "50%",
            },
          },
          // }
        },
      },
    },
  });

  return (
    <ThemeContext.Provider value={[themeCustom, setThemeCustom]}>
      <ThemeProviderInner theme={theme}>{children}</ThemeProviderInner>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
