export function cleanData(val) {
  const entries = Object.entries(val)
  var arr = []
  entries.forEach(([key, value]) => {
    const obj = {
      id: key,
      phone: value,
    }
    arr.push(obj)
  })
  return arr
}

export function cleanData2(val) {
  val.forEach(item => {
    if (item.id.substring(0, 1) == 'T') {
      item.id = 0
    } else {
      item.id = parseInt(item.id, 10)
    }
  })
  return val
}
