import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  textBold22: {
    color: "#252525",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  textBold16: {
    color: "#252525",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
  },
}));
