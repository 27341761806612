import React, { useEffect } from "react";
import useViewModel from "./ViewModel";
import PhonesDialog from "../components/PhonesDialog";
import { cleanData, cleanData2 } from "../utils/utils";

export default function EditAditionalPhones(props) {
  const { open, setOpen, id, refresh, setRefresh, kind } = props;
  const {
    data,
    getData,
    onClickToAdd,
    onClickToDelete,
    save,
    getDataNew,
  } = useViewModel();

  useEffect(() => {
    if (kind == 1) {
      getData(id);
    } else {
      getDataNew();
    }
  }, []);

  const confirm = async (val) => {
    const arr = cleanData(val); //convertir los datos del dom en un arreglo
    const arr2 = cleanData2(arr); //convertir los id a numericos
    await save(arr2, id); // grabar los datos del formulario
    await setRefresh(true); // refrescar la seccion de telefonos adicionales
    await setOpen(false); //cerrar el modal
  };
  const handleAddnewPhone = (val) => {
    onClickToAdd(val);
  };

  const handleDeletePhone = (val, index) => {
    onClickToDelete(val, index);
  };

  return (
    <PhonesDialog
      data={data}
      open={open}
      setOpen={setOpen}
      onClickAdd={handleAddnewPhone}
      onClickDelete={handleDeletePhone}
      confirm={confirm}
      kind={kind}
    />
  );
}
