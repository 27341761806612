import { context } from "../../../../../pages/constants";
export const titles = {
  textPhone: `Teléfono adicional`,
  bodyTitle: `Teléfonos adicionales`,
  editIconTitle: `editar`,
};

export const colors = {
  primaryColor: "#00269E",
};
