import React from "react";
import DialogComponent from "../../../../components/dialog/DialogComponent";
import { variantTypes } from "../../../../util/constants";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { translationKeys } from "./constants";
const ConfirmCancelModal = ({
  openModal,
  setOpenModal,
  handleConfirm,
  handleClose,
  handleCloseModal,
}) => {
  const { t } = useTranslation();

  return (
    <DialogComponent
      open={openModal}
      setOpen={setOpenModal}
      title={t(translationKeys.modalConfirmTitle)}
      modalSize={"30%"}
      handleClose={handleCloseModal}
      msg={
        <Box marginLeft={"24px"}>
          <p style={{ fontSize: "16px" }}>
            {t(translationKeys.modalConfirmLine1)}
          </p>
          <p>{t(translationKeys.modalConfirmLine2)}</p>
        </Box>
      }
      buttons={[
        {
          text: t(translationKeys.cancelar),
          action: handleClose,
          variant: variantTypes.outlined,
        },
        {
          text: t(translationKeys.confirmar),
          action: handleConfirm,
          variant: "",
        },
      ]}
    />
  );
};

export default ConfirmCancelModal;
