import React from "react";
import { DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { stylesConstants } from "../../util/constants";

const BootstrapDialogTitle = ({ children, handleClose, other }) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2rem",

          alignItems: stylesConstants.center,
        }}
      >
        {children}
        {handleClose ? (
          <div
            style={{
              width: "2rem",
              display: "flex",
              fontFamily: "Roboto",
              justifyContent: "flex-start",
              alignItems: "flex-start !important",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : null}
      </div>
    </DialogTitle>
  );
};

export default BootstrapDialogTitle;
