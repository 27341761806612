import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
export const CheckIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M20.0007 3.33337C10.8007 3.33337 3.33398 10.8 3.33398 20C3.33398 29.2 10.8007 36.6667 20.0007 36.6667C29.2007 36.6667 36.6673 29.2 36.6673 20C36.6673 10.8 29.2007 3.33337 20.0007 3.33337ZM20.0007 33.3334C12.6507 33.3334 6.66732 27.35 6.66732 20C6.66732 12.65 12.6507 6.66671 20.0007 6.66671C27.3507 6.66671 33.334 12.65 33.334 20C33.334 27.35 27.3507 33.3334 20.0007 33.3334ZM27.6507 12.6334L16.6673 23.6167L12.3507 19.3167L10.0007 21.6667L16.6673 28.3334L30.0007 15L27.6507 12.6334Z"
          fill="#4CAF50"
        />
      </svg>
    </SvgIcon>
  );
};
