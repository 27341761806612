import React, { useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { SimpleCard } from "../../../components/simpleCard/simpleCard";
import { InputTextController } from "../../../components/Inputs/InputTextController";
import SimpleSelectAutoComplete from "../../../components/selects/simpleSelectAutoComplete";
import { useTranslation } from "react-i18next";
import { translationKeys } from "./constants.js";
import { useStyles } from "./styles";

import { Phone } from "saas-ui-design";
import EditAditionalPhones from "./aditionalPhones/edit/EditAditionalPhones";
import ListAditionalPhones from "./aditionalPhones/list/ListAditionalPhones";
import useViewModel from "./viewModel";
import InputPlaces from "../../../components/Inputs/InputPlaces";
import ConfirmCancelModal from "../components/ConfirmCancelModal";

export const BasicData = (props) => {
  const { onClickNext, refreshPage } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const {
    countries,
    economicActivity,
    cities,
    comunes,
    control,
    handleSubmit,
    onSummit,
    identificationTypeText,
    cityLabel,
    communeLabel,
    getIsDisabled,
    onClickCancel,
    showCancelModal,
    setShowCancelModal,
  } = useViewModel(onClickNext, refreshPage);

  const handleAddnewPhone = () => {
    setOpenNew(true);
  };

  const handleClickToEdit = () => {
    setOpenEdit(true);
  };

  const cardActions = () => {
    return (
      <Button color="primary" onClick={handleAddnewPhone} variant={"text"}>
        {t(translationKeys.add_aditional_phones)}
      </Button>
    );
  };

  const handleConfirm = () => {
    window.location.reload(false);
  };

  const handleClose = () => {
    setShowCancelModal(false);
  };

  const handleCloseModal = () => {
    setShowCancelModal(false);
  };

  return (
    <>
      <form action="/">
        <SimpleCard title={t(translationKeys.basic_data)}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} sm={6} md={4}>
              <InputTextController
                name={"bussinesname"}
                id={"bussinesname"}
                control={control}
                label={t(translationKeys.bussinesname)}
                placeholder={"Ingresa"}
                showHelperText={false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputTextController
                name={"operativename"}
                id={"operativename"}
                control={control}
                label={t(translationKeys.operativename)}
                placeholder={"Ingresa"}
                showHelperText={false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputTextController
                name={"dni"}
                id={"dni"}
                control={control}
                label={identificationTypeText}
                placeholder={"Ingresa"}
                showHelperText={false}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <SimpleSelectAutoComplete
                name={"economicActivity"}
                control={control}
                label={t(translationKeys.economicactivity)}
                placeholder={"Selecciona"}
                helperText={""}
                required={true}
                options={economicActivity}
                variant={"outlined"}
                sort={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <InputTextController
                name={"workers"}
                id={"workers"}
                control={control}
                label={t(translationKeys.workers)}
                placeholder={"Ingresa"}
                showHelperText={false}
                type={"number"}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <InputTextController
                name={"mobileunits"}
                id={"mobileunits"}
                control={control}
                label={t(translationKeys.mobileunits)}
                placeholder={"Ingresa"}
                showHelperText={false}
                type={"number"}
                required
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <InputPlaces
                id={"address"}
                name={"address"}
                control={control}
                required={true}
                helperText={""}
                label={t(translationKeys.address)}
                placeholder={t(translationKeys.address)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <SimpleSelectAutoComplete
                name={"country"}
                control={control}
                label={t(translationKeys.country)}
                placeholder={"Selecciona"}
                helperText={""}
                required={true}
                options={countries}
                variant={"outlined"}
                sort={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SimpleSelectAutoComplete
                name={"city"}
                control={control}
                label={cityLabel}
                placeholder={"Selecciona"}
                helperText={""}
                required={true}
                options={cities}
                variant={"outlined"}
                sort={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SimpleSelectAutoComplete
                name={"commune"}
                control={control}
                label={communeLabel}
                placeholder={"Selecciona"}
                helperText={""}
                required={true}
                options={comunes}
                variant={"outlined"}
                sort={true}
              />
            </Grid>
          </Grid>
        </SimpleCard>
        <SimpleCard
          title={t(translationKeys.contact_information)}
          cardActions={cardActions}
          overrideClassesCard={classes.lastCard}
        >
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} sm={6} md={4}>
              <InputTextController
                name={"firstname"}
                id={"firstname"}
                control={control}
                label={t(translationKeys.firstname)}
                placeholder={"Ingresa"}
                showHelperText={false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputTextController
                name={"lastname"}
                id={"lastname"}
                control={control}
                label={t(translationKeys.lastname)}
                placeholder={"Ingresa"}
                showHelperText={false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputTextController
                name={"email"}
                id={"email"}
                type={"email"}
                control={control}
                label={t(translationKeys.email)}
                placeholder={"Ingresa"}
                showHelperText={false}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Phone
                key={"channelphone"}
                name={"channelphone"}
                control={control}
                defaultValue={"+52"}
                label={t(translationKeys.channelphone)}
                required={true}
                localization="es"
                disableDropdown={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Phone
                key={"contactphone"}
                name={"contactphone"}
                control={control}
                defaultValue={"+52"}
                label={t(translationKeys.contactphone)}
                required={true}
                localization="es"
                disableDropdown={true}
              />
            </Grid>
          </Grid>

          <>
            <ListAditionalPhones
              id={1}
              onClickToEdit={handleClickToEdit}
              refresh={refresh}
              setRefresh={setRefresh}
              showButton={showButton}
              setShowButton={setShowButton}
            />
          </>
          {showButton && (
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                borderTop: "solid 1px #E5E5E5",
                marginTop: "15px",
                marginLeft: "-16px",
                marginRight: "-16px",
                paddingTop: "16px",
              }}
            >
              <Button
                color="primary"
                onClick={handleAddnewPhone}
                variant={"text"}
                style={{ marginRight: "16px" }}
              >
                {t(translationKeys.add_aditional_phones)}
              </Button>
            </Box>
          )}
        </SimpleCard>

        <Box
          style={{
            display: "flex",
            width: "inherit",
            justifyContent: "flex-end",
            marginBottom: "20px",
            marginRight: "20px",
            columnGap: "25px",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={onClickCancel}
            size="medium"
          >
            {t(translationKeys.cancel)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleSubmit(onSummit)}
            disabled={getIsDisabled()}
          >
            {t(translationKeys.next)}
          </Button>
        </Box>
      </form>
      {openEdit && (
        <EditAditionalPhones
          open={openEdit}
          setOpen={setOpenEdit}
          id={1}
          refresh={refresh}
          setRefresh={setRefresh}
          kind={1}
        />
      )}
      {openNew && (
        <EditAditionalPhones
          open={openNew}
          setOpen={setOpenNew}
          id={1}
          refresh={refresh}
          setRefresh={setRefresh}
          kind={0}
        />
      )}

      <ConfirmCancelModal
        openModal={showCancelModal}
        setOpenModal={setShowCancelModal}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};
