import React from "react";
import { Box, Typography } from "@material-ui/core";
import { CheckIcon } from "../../../components/CheckIcon";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { translationKeys } from "./constants.js";

export const Successfull = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "800px",
      }}
    >
      <CheckIcon fontSize="large" />
      <Typography
        className={classes.textBold34}
        style={{ marginTop: "16px", width: "350px", textAlign: "center" }}
      >
        {t(translationKeys.title)}
      </Typography>
      <Typography
        className={classes.textBold14}
        style={{ marginTop: "40px", width: "500px", textAlign: "center" }}
      >
        {t(translationKeys.subTitle1)}
      </Typography>
      <Typography
        className={classes.textBold14}
        style={{ width: "450px", textAlign: "center" }}
      >
        {t(translationKeys.subTitle2)}
      </Typography>
    </Box>
  );
};
