import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useViewModel from './ViewModel'
import ListHeader from './ListHeader'
import ListBody from './ListBody'
import { useStyles } from './styles'

export default function ListAditionalPhones(props) {
  const { id, onClickToEdit, refresh, showButton, setShowButton, setRefresh } =
    props
  const { t } = useTranslation()
  const classes = useStyles()
  const { data, getData, isLoading } = useViewModel()

  useEffect(() => {
    getData(id)
    setRefresh(false)
  }, [refresh])

  useEffect(() => {
    if (data.length > 0) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  }, [data])

  return (
    <>
      {data.length == 0 && <div></div>}
      {data.length > 0 && (
        <Box sx={{ flexGrow: 1 }} className={classes.container}>
          <ListHeader isLoading={isLoading} onClickToEdit={onClickToEdit} />
          <ListBody data={data} />
        </Box>
      )}
    </>
  )
}
