import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  textBold34: {
    color: "#000",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  textBold14: {
    color: "#171718",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
  },
}));
