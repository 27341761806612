export const hexColorDefault = {
  primaryBlue: "#002FC5", // Azul Primario
};

export const translationKeys = {
  select: "Seleccciona",
};

export const stylesConstants = {
  primary: "primary",
  standardModalSize: "1053px",
  standardContentSize: "100%",
  heightProfessionalModal: "520px",
  row: "row",
  fitContent: "fit-content",
  auto: "auto",
  rgbStandard: "rgba(0, 0, 0, 0.87)",
  twentyPixels: "20px",
  sixteenPixels: "16px",
  standardPercentage: "100%",
  important: "!important",
  sixHundred: "600",
  none: "none",
  backgroundColorDialog: "#80808066",
  sixHundredPixels: "600px",
  paddingBase: "0 20px 0 24px",
  paddingModalReport: "20px 0 20px 24px",
  column: "column",
  center: "center",
  hidden: "hidden",
  fourtyPixels: "40px",
  threeHundredSixtyPixels: "360px",
  flexEnd: "flex-end",
  labelPlacement: "end",
  colorError: "#f44336",
  flex: "flex",
  left: "left",
  fontSizeBasic: "18px",
  end: "end",
  bottom: "bottom",
  oneHundredPercent: "100%",
};

export const classesNames = {
  containerModal: "container-modal",
  customizedDialogTitle: "customized-dialog-title",
  scrollDialogDescription: "scroll-dialog-description",
};

export const componentTypes = {
  div: "div",
  button: "button",
  label: "label",
  a: "a",
};

export const variantTypes = {
  outlined: "outlined",
  text: "text",
  contained: "contained",
  filled: "filled",
  standard: "standard",
  popover: "popover",
  paper: "paper",
  email: "email",
  number: "number",
};

export const typeofValue = {
  undefined: "undefined",
  null: "object", // Same as 'object'
  object: "object",
  boolean: "boolean",
  number: "number",
  bigint: "bugint",
  string: "string",
  symbol: "symbol",
  function: "function",
};
export const inputTypes = {
  file: "file",
  text: "text",
};
