import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { titles } from './constants'
import { useTranslation } from 'react-i18next'

export default function ListBody(props) {
  const { data } = props
  const { t } = useTranslation()
  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      {data.map((item, index) => {
        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <div style={{ display: 'flex' }}>
              <Typography>
                {t(titles.textPhone)} {index + 1} : {item.formatPhone}</Typography>
            </div>
          </Grid>
        )
      })}
    </Grid>
  )
}
