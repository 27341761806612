import React, { useState, useEffect } from "react";
import { Typography, Grid, IconButton, Tooltip, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../constants";
import { useStyles } from "../styles";
import { UploadButton } from "../../../../components/UploadButton";
import InfoIcon from "@material-ui/icons/Info";
import ClearIcon from "@material-ui/icons/Clear";
import TransitionAlerts from "../../../../components/Alert";
import { useForm } from "react-hook-form";
import { parseFileWeight } from "../../../../util/commons";

export const UploadDocs = ({ item, updateDocs }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting },
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const handleDeleteFile = () => {
    updateDocs({ ...data, evento: "delete" });
    setData(null);
  };

  useEffect(() => {
    if (data) {
      const doc = {
        tipo: item.tipo,
        data: data,
        evento: "add",
        documentationName: item.documentationName,
      };
      updateDocs(doc);
    }
  }, [data]);

  return (
    <>
      <Grid item xs={12}>
        <Typography className={classes.normaltext}>
          {item.documentationName}
          <span className={classes.normaltextRed}> *</span>
        </Typography>
      </Grid>

      {data?.error == true ? (
        <Grid item xs={6} style={{ translate: "0px -15px" }}>
          <TransitionAlerts
            text={t(translationKeys.alertText)}
            open={data?.error}
            severity={"error"}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={6}>
            <Box className={classes.fileBox}>
              {data?.filename ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title={data.filename} placement="right" arrow>
                    <Typography className={classes.fileText}>
                      {data.filename}
                    </Typography>
                  </Tooltip>
                  <Typography
                    className={`${classes.principalText} ${classes.paddingFileWeigth}`}
                  >
                    ({parseFileWeight(data.fileWeight)})
                  </Typography>
                  <IconButton onClick={handleDeleteFile}>
                    <ClearIcon fontSize="medium" />
                  </IconButton>
                </div>
              ) : (
                <Typography className={classes.noFileText}>
                  {"No hay archivos seleccionados"}
                </Typography>
              )}
            </Box>
          </Grid>
        </>
      )}

      <Grid item xs={6}>
        <div style={{ paddingLeft: "21px" }}>
          <UploadButton
            data={data}
            setData={setData}
            text={"Seleccionar archivo"}
            variant={"outlined"}
            color={"primary"}
            formats=".jpg,.png,.pdf"
          />
          <Tooltip
            title={
              <Typography className={classes.tooltip}>
                {t(translationKeys.alertInfo)}
              </Typography>
            }
            aria-label="add"
            placement="right"
            arrow
          >
            <IconButton aria-label="delete">
              <InfoIcon style={{ color: "#2196F3" }} fontSize="medium" />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
    </>
  );
};
