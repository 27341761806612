import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    paddingTop: "15px",
    paddingBottom: "15px",
  },
}));

export default function TransitionAlerts({ severity, actions, text, open }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert severity={severity} action={actions}>
          {text}
        </Alert>
      </Collapse>
    </div>
  );
}
