import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { translationKeys } from "./constants.js";

export const PageTitle = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.textBold22}>
        {t(translationKeys.title)}
      </Typography>
      <Box style={{ marginTop: "25px" }}>
        <Typography className={classes.textBold16}>
          {t(translationKeys.subTitle1)}
        </Typography>
        <Typography className={classes.textBold16}>
          {t(translationKeys.subTitle2)}
        </Typography>
      </Box>
    </>
  );
};
