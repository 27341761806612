export const translationKeys = {
  Documentation: "Documentación",
  title: "Agrega los documentos en los campos correspondientes.",
  no_selected_files: "No hay archivos seleccionados",
  select_file: "Seleccionar archivo",
  alertInfo: "Tamaño máximo 10 MB. Formatos permitidos PDF, JPG Y PNG. ",
  alertInfoImage:
    "Tamaño máximo 10 MB.Formatos permitidos PDF, JPG Y PNG. 5 archivos máximo.",
  cancel: "Cancelar",
  next: "CONTINUAR",
  back: "Atrás",
  nextTooltip:
    "Para continuar es necesario completar los campos obligatorios (*).",
  alertMaxFiles: "La carga máxima es de 5 archivos.",
  alertText: "Tamaño máximo 10 MB. Formatos permitidos PDF, JPG Y PNG.",
};
