import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  asterikColor: {
    color: "rgba(245, 0, 87, 1)",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${
        theme.palette.highContrast ? theme.palette.primary.main : "#000"
      } !important`,
    },
  },

  cssLabel: {
    fontWeight: "400",
    color: "#000000",
    opacity: "0.75",
  },
  cssFocused: {
    color: `${
      theme.palette.highContrast ? theme.palette.primary.main : "#000"
    } !important`,
  },
  cssError: {
    color: `${theme.palette.error.main} !important`,
  },
}));

export const InputPropsDefault = () => {
  const classes = useStyles();
  return {
    shrink: true,
    classes: {
      asterisk: classes.asterikColor,
      root: classes.cssLabel,
      focused: classes.cssFocused,
      error: classes.cssError,
    },
  };
};
