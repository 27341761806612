import React from "react";
import { makeStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextPlacesAutoComplete, {
  suggestionTypes,
} from "./textplacesautocomplete.tsx";

export const translationKeys = {
  helperTextAddress: ``,
  helperTextAddressIncorrect: ``,
};

export const useStyles = makeStyles(() => ({
  grid: {
    padding: "9px 9px 0 9px !important",
  },
}));

export { suggestionTypes };

const InputPlaces = ({
  id,
  control,
  required,
  helperText,
  label,
  placeholder,
  suggestionType = suggestionTypes.suggestion2,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TextPlacesAutoComplete
          name={id}
          required={required}
          id={id}
          label={label}
          value={value && value.value ? value.value : value || ""}
          disabled={false}
          isModal={true}
          onChange={onChange}
          setAddress={onChange}
          error={error}
          placeholder={placeholder}
          helperText={(error && error?.message) || helperText}
          fullWidth={true}
          inputRef={ref}
          suggestionType={suggestionType}
        />
      )}
      rules={{
        required: true,
        validate: (value) => {
          if (!value) {
            return t(translationKeys.helperTextAddress);
          }
          if (!value?.value) {
            return t(translationKeys.helperTextAddressIncorrect);
          }
        },
      }}
    />
  );
};

export default InputPlaces;
