import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { Controller } from "react-hook-form";
import SelectAutoComplete from "./selectAutoComplete";
import { string, array, bool, func, object } from "prop-types";
import CustomEndAdornament from "../CustomEndAdornament";
import { useTranslation } from "react-i18next";
import { Typography, Tooltip } from "@material-ui/core";

const translationKeys = {
  commonLabel: `commonLabel`,
  commonLabelNoOptions: `No hay opciones disponibles`,
};

const SimpleSelectAutoComplete = ({
  name,
  control,
  options,
  disabled,
  label,
  placeholder,
  variant,
  required,
  helperText,
  validate,
  takeEvent,
  loading,
  sort,
  toolTipText,
  classes,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value;

  const getOptions = () => {
    let optionsFiltered = options.filter((item) => item.label);
    if (sort)
      return optionsFiltered.sort((a, b) => a.label.localeCompare(b.label));
    return optionsFiltered;
  };

  const AutocompleteEditable = (value, onChange, error, ref) => {
    return (
      <Autocomplete
        label={label}
        value={value}
        placeholder={placeholder || t(translationKeys.commonLabel)}
        getOptionLabel={(option) => (option.label ? option.label : "")}
        inputValue={inputValue || value?.label || ""}
        onInputChange={(_e, newvalue) => {
          setInputValue(newvalue);
          if (!newvalue) onChange(newvalue);
        }}
        onClose={() => setInputValue("")}
        getOptionSelected={getOptionSelected}
        renderInput={(params) => (
          <SelectAutoComplete
            params={{
              ...params,
              InputProps: {
                ...params.InputProps,
                endAdornment: (
                  <CustomEndAdornament
                    params={{ ...params.InputProps.endAdornment }}
                  />
                ),
              },
            }}
            label={label}
            placeholder={placeholder || t(translationKeys.commonLabel)}
            variant={variant}
            required={required}
            error={!!error}
            helperText={helperText}
            inputRef={ref}
            loading={loading}
          />
        )}
        onChange={(e, optionSelected) => {
          if (takeEvent) onChange(e);
          if (optionSelected) onChange(optionSelected);
        }}
        options={getOptions()}
        disabled={disabled}
        required={required}
        loading={loading}
        noOptionsText={t(translationKeys.commonLabelNoOptions)}
        getOptionDisabled={(option) => option.disabled}
      />
    );
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ref, value }, fieldState: { error } }) => {
        return (
          <>
            {toolTipText && (
              <Tooltip
                title={
                  <Typography className={classes?.toolTip}>
                    {toolTipText}
                  </Typography>
                }
                aria-label="add"
                placement="right"
                arrow
              >
                {AutocompleteEditable(value, onChange, error, ref)}
              </Tooltip>
            )}
            {!toolTipText && AutocompleteEditable(value, onChange, error, ref)}
          </>
        );
      }}
      rules={{ required: required, validate: validate }}
    />
  );
};

SimpleSelectAutoComplete.propTypes = {
  name: string, // array with object options
  control: object, // object control react-hook-form
  options: array, // id to identify element
  dataSelected: object,
  label: string,
  placeholder: string,
  variant: string,
  required: bool,
  helperText: string,
  validate: func,
  loading: bool,
  sort: bool,
};

SimpleSelectAutoComplete.defaultProps = {
  options: [],
  dataSelected: null,
  required: false,
  helperText: "",
  validate: () => {},
  loading: false,
  sort: false,
};

export default SimpleSelectAutoComplete;
