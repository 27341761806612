import { Typography, Box } from "@material-ui/core";

export const CostsAndZones = () => {
  return (
    <>
      <Box style={{ height: "500px" }}>
        <Typography variant="h1">Costos y zonas</Typography>
      </Box>
    </>
  );
};
