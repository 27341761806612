/* eslint-disable react/prop-types */
import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { hexColorDefault } from '../../util/constants'

const useStyles = makeStyles(theme => {
  return {
    ButtonBase: {
      margin: '0 25px 0 0 !important',
    },
    text: {
      color: theme.palette.highContrast
        ? theme.palette.primary.main
        : theme.palette.primary.main === hexColorDefault.primaryBlue
        ? theme.palette.primary.main
        : '#000',
    },
    textContained: {
      paddingTop: '1px',
      color: theme.palette.highContrast
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main === hexColorDefault.primaryBlue
        ? '#fff'
        : '#000',
    },
    root: {
      padding: '6px 16px',
      '&.MuiButtonBase-root': {
        padding: '6px 16px',
        borderRadius: '4px',
      },
    },
    rootContained: {
      padding: '6px 16px',
      backgroundColor: theme.palette.primary.main,
    },
  }
})

const MButton = ({
  text,
  onClick,
  variant,
  disabled,
  startIcon,
  type,
  style,
  overrideClasses,
  size,
}) => {
  const classes = useStyles()
  const classByVariant =
    !variant || variant === 'contained' ? classes.textContained : classes.text
  const getRootByVariant = () => {
    return variant === 'contained' ? classes.rootContained : classes.root
  }
  const overwriteStyles = !disabled
    ? {
        label: classByVariant,
        root: getRootByVariant(),
      }
    : { root: getRootByVariant() }

  return (
    <Button
      size={size || 'medium'}
      variant={variant || 'contained'}
      color="primary"
      type={type}
      onClick={onClick}
      style={{ ...style }}
      disabled={disabled ? true : false}
      className={`${classes.ButtonBase} ${overrideClasses}`}
      classes={overwriteStyles}
      startIcon={startIcon}
    >
      {text}
    </Button>
  )
}

const MaterialButton = MButton

export { MaterialButton }
