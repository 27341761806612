import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  root: {},
  button: {
    marginRight: '0px !important',
  },
  lastCard: {
    paddingBottom: '0px !important',
  },
}))
