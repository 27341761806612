import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, Box, Button, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SimpleCard } from "../../../components/simpleCard/simpleCard";
import { translationKeys } from "./constants";
import useViewModel from "./viewModel";
import { useStyles } from "./styles";
import { useForm } from "react-hook-form";
import { UploadDocs } from "./components/uploadDocs";
import { UploadImages } from "./components/uploadImages";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { PostulationContext } from "../../../util/postulationContext";
import ConfirmCancelModal from "../components/ConfirmCancelModal";

export const Documentation = (props) => {
  const { onClickBack, onClickCancel, onClickNext } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const { documentTypes } = useViewModel();
  const [tdata, setTData] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const [minDocRequired, setMinDocRequired] = useState(0);
  const [globalData, setGlobalData] = useContext(PostulationContext);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting },
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const updateDocs = (value) => {
    if (value) {
      if (value.evento === "add") {
        setTData([...tdata, value]);
      }
      if (value.evento === "delete") {
        const idx = tdata.findIndex(
          (item) => item.data.filename === value.filename
        );
        let newData = [...tdata];
        newData.splice(idx, 1);
        setTData(newData);
      }
    }
  };

  useEffect(() => {
    console.log("tdata", tdata);
    if (tdata.length > 0) {
      const tDataTipo1 = tdata.filter((item) => {
        return item.tipo == 1;
      });
      const tDataTipo2 = tdata.filter((item) => {
        return item.tipo == 2;
      });
      const len1 = tDataTipo1.length > 0 ? 1 : tDataTipo1.length;
      const len2 = tDataTipo2.length;
      const totalActual = len1 + len2;
      if (totalActual < minDocRequired) {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    } else {
      setDisableBtn(true);
    }
  }, [tdata]);

  useEffect(() => {
    if (documentTypes.length > 0) {
      const res = documentTypes.filter((item) => {
        return item.tipo === 2;
      });
      const len = res.length + 1;
      setMinDocRequired(len);
    }
  }, [documentTypes]);

  const onSubmit = () => {
    const info = { ...globalData };
    info.files = tdata;
    setGlobalData(info);
    onClickNext();
  };

  const handleConfirm = () => {
    onClickCancel();
  };

  const handleClose = () => {
    setShowCancelModal(false);
  };

  const handleCloseModal = () => {
    setShowCancelModal(false);
  };

  const onClickCancelButton = () => {
    setShowCancelModal(true);
  };

  return (
    <>
      <SimpleCard title={t(translationKeys.Documentation)}>
        <Typography className={classes.subTitle}>
          {t(translationKeys.title)}
        </Typography>
        <Grid container spacing={3} direction="row">
          {documentTypes
            .filter((item) => {
              return item.tipo === 2;
            })
            .map((item, idx) => {
              return (
                <UploadDocs item={item} key={idx} updateDocs={updateDocs} />
              );
            })}
          {documentTypes
            .filter((item) => {
              return item.tipo === 1;
            })
            .map((item, idx) => {
              return (
                <UploadImages item={item} key={idx} updateDocs={updateDocs} />
              );
            })}
        </Grid>
        <Grid />
      </SimpleCard>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "20px",
          height: "36px",
          marginBottom: "20px",
        }}
      >
        <Button
          color="primary"
          size="medium"
          onClick={onClickBack}
          startIcon={<ArrowBackIcon />}
          style={{ width: "112px" }}
        >
          {t(translationKeys.back)}
        </Button>

        <Box
          style={{
            display: "flex",
            columnGap: "25px",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={onClickCancelButton}
            size="medium"
          >
            {t(translationKeys.cancel)}
          </Button>
          <div>
            {disableBtn && (
              <Tooltip
                title={
                  <Typography className={classes.tooltip}>
                    {t(translationKeys.nextTooltip)}
                  </Typography>
                }
                aria-label="add"
                placement="top"
                arrow
              >
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={onSubmit}
                    disabled={disableBtn}
                  >
                    {t(translationKeys.next)}
                  </Button>
                </span>
              </Tooltip>
            )}
            {!disableBtn && (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={onSubmit}
              >
                {t(translationKeys.next)}
              </Button>
            )}
          </div>
        </Box>
      </Box>
      <ConfirmCancelModal
        openModal={showCancelModal}
        setOpenModal={setShowCancelModal}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};
